<template>
  <div></div>
</template>
<script type="text/javascript"> 
export default {

  data: () => ({
    name:"",
    dialog: false
  }),
  created(){
    this.logout();
  },
    methods: {
      
      logout(){
        
          this.$store.dispatch('resetAllState');
        //   this.$auth.setAuth('logout');
          this.$router.push({name:'login'});
      }
    } 
 }
</script>