<template>
<v-app>  
    <AppBar/>
    <v-main class="d-flex align-center text-center">
        <h2>嗨？</h2>
        <v-container class="px-6 px-sm-16">
            <v-form ref="form" lazy-validation v-model="valid">
                <v-text-field
                    outlined
                    v-model="account"
                    placeholder="請輸入電子郵件"
                    :rules="$store.getters['validation/inputRules'](['required','gfk_email'])"  
                    required
                ></v-text-field>
                <v-text-field
                    type="password"
                    outlined
                    v-model="password"
                    placeholder="請輸入密碼"
                    :rules="$store.getters['validation/inputRules'](['required'])"
                    required
                ></v-text-field>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-btn class="d-flex justify-end just" color="accent" text @click="sheet1=true">忘記密碼?</v-btn>
                </v-row>
                <v-row>
                    <v-col>
                        <p class="red--text" v-html="errMsg"></p>
                        <v-btn block color="primary" :disabled="!valid" @click="onSubmit" class="mb-2">登入</v-btn>
                        <v-btn block text @click="$router.push({path:'/register'});">還沒有帳號嗎？<span class="secondary--text">點此開始註冊</span></v-btn>
                    </v-col>
                </v-row>
               
            </v-form>
        </v-container>
        <v-bottom-sheet v-model="sheet1" persistent>
          <v-card class="text-center" flat>
              <v-card-title>
                  <span>忘記密碼</span>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="sheet1 = !sheet1"
                  > <v-icon>mdi-close</v-icon> </v-btn>
              </v-card-title>
              <v-form ref="form2">
                  <v-container class="px-6">
                      <v-row>
                          <v-col class="pr-8 pb-0">
                              <v-text-field
                                  v-model="account"
                                  :rules="$store.getters['validation/inputRules'](['required'])"
                                  label="帳號"
                              ></v-text-field>
                          </v-col>
                      </v-row>
                      <v-btn color="primary" width="150"
                          @click="sendPasswordEmail"
                      >送出驗證信</v-btn>
                  </v-container>
              </v-form>
        </v-card>
       </v-bottom-sheet>
    </v-main>
</v-app>
</template>
<script type="text/javascript">
import AppBar from '@/components/common/AppBar'
export default {
    components:{AppBar},
  data: () => ({
    userAgent:{},
    valid: true,
    errMsg:'',
    account: '',
    password: '',
    sheet1:false
  }),

  methods: {
    sendPasswordEmail(){
      if (this.account!="") {

        this.$store.commit('loadingBox',{show:true});
        this.$store.dispatch("http/post",{
            api:"users/email/forgotpassword",
            json:{
              account:this.account,
            }
            
          }).then((data)=>{
            this.sheet1=false

            if(data.status){
                  setTimeout(()=>{
                      this.$store.commit('loadingBox',{show:false});
                      this.$store.commit('dialogBox',{dialog:true,option:{
                          btnConfirmText:'我知道了',
                          message:'忘記密碼驗證信已經發送至GfK信箱，請立即收信並重新設定密碼！',
                          btnCancelShow:false,
                          icon:'success',
                          btnConfirmEvent:()=>{
                          }
                      }});
                  },2500);
              }else{
                  this.$store.commit('loadingBox',{show:false});
                  this.errMsg="伺服器發生錯誤請稍後再試";
              }
          })
      }
    },
    dialogHandle(){
      this.$emit('dialogHandle');
    },
    onSubmit () {
    
      this.errMsg="";
      if (this.$refs.form.validate()) {
        
        this.$store.commit('loadingBox',{show:true});
        this.$store.dispatch("http/post",{
          api:"login",
          json:{
            account:this.account,
            password:this.password
          }
          
        }).then((data)=>{
          setTimeout(()=>{
            this.$store.commit('loadingBox',{show:false});
            if(data.status){
                this.$store.commit('user/setUserLogin',data.result);
                this.$router.push({path:'/map'});
            }else{
              this.errMsg="帳號或密碼錯誤";
            }
          },2500);
        });
        
      }
    },
    clear () {
      this.$refs.form.reset()
    }
  }
}
</script>