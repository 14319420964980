<template>
<v-app>  
    <AppBar/>
    <v-main class="d-flex align-center text-center">
        <h2>立即註冊一個帳號吧！</h2>
        <v-container class="px-6 px-sm-16">
            <v-form ref="form" lazy-validation v-model="valid">
                <v-text-field
                    outlined
                    v-model="user_name"
                    placeholder="姓名"
                    :rules="$store.getters['validation/inputRules'](['required'])"  
                    required
                ></v-text-field>
                <v-text-field
                    outlined
                    v-model="account"
                    placeholder="請輸入電子郵件"
                    :rules="$store.getters['validation/inputRules'](['required','gfk_email'])"  
                    required
                ></v-text-field>
                <v-text-field
                    type="password"
                    outlined
                    v-model="password"
                    placeholder="請輸入密碼"
                    :rules="$store.getters['validation/inputRules'](['required','password'])"
                    required
                ></v-text-field>
                <v-text-field
                    type="password"
                    outlined
                    v-model="confirm_password"
                    placeholder="再次確認密碼"
                    :rules="$store.getters['validation/inputRules'](['passwordCheck'],password)"
                    required
                ></v-text-field>
    
                <v-row>
                    <v-col>
                        <p class="red--text" v-html="errMsg"></p>
                        <v-btn block color="primary" :disabled="!valid" @click="onSubmit" class="mb-2">註冊</v-btn>
                        <v-btn block text @click="sheet1=true">沒收到驗證信？<span class="secondary--text">點此重新發送</span></v-btn>

                        <v-btn block text @click="$router.push({path:'/login'});">已經有帳號了嗎？<span class="secondary--text">點此登入</span></v-btn>
                    </v-col>
                </v-row>

            </v-form>
        </v-container>
        <v-bottom-sheet v-model="sheet1" persistent>
          <v-card class="text-center" flat>
              <v-card-title>
                  <span>重新寄送驗證信</span>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="sheet1 = !sheet1"
                  > <v-icon>mdi-close</v-icon> </v-btn>
              </v-card-title>
              <v-form ref="form2">
                  <v-container class="px-6">
                      <v-row>
                          <v-col class="pr-8 pb-0">
                              <v-text-field
                                  v-model="account"
                                  :rules="$store.getters['validation/inputRules'](['required'])"
                                  label="帳號"
                              ></v-text-field>
                          </v-col>
                      </v-row>
                      <v-btn color="primary" width="150"
                          @click="sendVerifyEmail"
                      >送出驗證信</v-btn>
                  </v-container>
              </v-form>
        </v-card>
       </v-bottom-sheet>
    </v-main>
</v-app>
</template>
<script type="text/javascript">
import AppBar from '@/components/common/AppBar'

export default {
    components:{AppBar},

  created(){
      
  },
  data: () => ({
    valid: true,
    sheet1:false,
    errMsg:'',
    account: '',
    password: '',
    confirm_password: '',
    user_name: '',
  }),

  methods: {
    sendVerifyEmail(){
        if (this.account!="") {

            this.$store.commit('loadingBox',{show:true});
            this.$store.dispatch("http/post",{
                api:"users/email/verify",
                json:{
                    account:this.account,
                }
                
            }).then((data)=>{
                if(data.status){
                    setTimeout(()=>{
                        this.$store.commit('loadingBox',{show:false});
                            this.$store.commit('dialogBox',{dialog:true,option:{
                                btnConfirmText:'我知道了',
                                message:'驗證信已經發送至GfK信箱，請立即收信並驗證你的帳號！',
                                btnCancelShow:false,
                                icon:'success',
                                btnConfirmEvent:()=>{
                                }
                            }});
                    },2500);
                }else{
                    this.$store.commit('loadingBox',{show:false});
                    this.errMsg="伺服器發生錯誤請稍後再試";
                }
                
            })
        }
    },
    dialogHandle(){
      this.$emit('dialogHandle');
    },
    onSubmit () {
    
      this.errMsg="";
      if (this.$refs.form.validate()) {
        
        this.$store.commit('loadingBox',{show:true});
        this.$store.dispatch("http/post",{
          api:"users",
          json:{
            account:this.account,
            password: this.password,
            confirm_password: this.confirm_password,
            user_name: this.user_name,
          }
          
        }).then((data)=>{
          setTimeout(()=>{
            if(data.status!=1){
                this.$store.commit('loadingBox',{show:false});
            }
            if(data.status){
                this.sendVerifyEmail()
            }else if(data.code==3){
              this.errMsg="此帳號已經存在";
            }else{
              this.errMsg="伺服器發生錯誤請稍後再試";
            }
          },2500);
        });
        
      }
    },
    clear () {
      this.$refs.form.reset()
    }
  }
}
</script>