<template>
<v-app>
<v-main>
    <v-toolbar color="primary" class="white--text" v-if="isForgotPage">
      <v-toolbar-title>GfK UCAPP 忘記密碼重置</v-toolbar-title>
    </v-toolbar>
    <v-toolbar color="primary" class="white--text" v-else>
        <v-btn icon @click="$router.go(-1)">
            <v-icon color="white">mdi-chevron-left</v-icon>
        </v-btn>
      <v-toolbar-title>變更密碼</v-toolbar-title>
    </v-toolbar>
    <v-container class="mt-4">
        <v-form ref="form" lazy-validation v-model="valid">
                
                <v-text-field v-if="isForgotPage==false"
                    type="password"
                    outlined
                    v-model="old_password"
                    placeholder="請輸入舊密碼"
                    :rules="$store.getters['validation/inputRules'](['required'])"
                    required
                ></v-text-field>
                <v-text-field v-if="isForgotPage"
                    outlined
                    v-model="account"
                    placeholder="帳號"
                    :rules="$store.getters['validation/inputRules'](['required'])"
                    required
                ></v-text-field>
                <v-text-field
                    type="password"
                    outlined
                    v-model="new_password"
                    placeholder="請輸入新密碼"
                    :rules="$store.getters['validation/inputRules'](['required','password'])"
                    required
                ></v-text-field>
                <v-text-field
                    type="password"
                    outlined
                    v-model="confirm_password"
                    placeholder="再次確認密碼"
                    :rules="$store.getters['validation/inputRules'](['passwordCheck'],new_password)"
                    required
                ></v-text-field>
    
                <v-row>
                    <v-col>
                        <p class="red--text" v-html="errMsg"></p>
                        <v-btn block color="primary" :disabled="!valid" @click="onSubmit" class="mb-2">送出</v-btn>
                        <v-btn v-if="isForgotPage" block text @click="$router.push({path:'/login'});">終於想起密碼了嗎？<span class="secondary--text">點此登入</span></v-btn>
                    </v-col>
                </v-row>

            </v-form>
    </v-container>
   </v-main>
</v-app>
</template>
 

<script>
export default {
  components:{},
  props:[],
  data () {
    return {
        valid:false,
        errMsg:"",
        account:"",
       old_password:"",
       new_password:"",
       confirm_password:"",
    }
  },
  computed:{
     isForgotPage(){
         return this.$route.path=="/password/reset"?false:true
     },
     token(){
         return this.$route.query.token
     }
    },
  mounted(){
     console.log(this.$route.query)
  },
    methods:{
        onSubmit(){
            if (this.$refs.form.validate()) {

                if(this.isForgotPage){
                    
                        this.$store.dispatch("http/put",{  
                            api:"users/password/forgot",
                            json:{
                                account:this.account,
                                token:this.token,
                                new_password:this.new_password,
                                confirm_password:this.confirm_password
                            }
                        
                        }).then((data)=>{
                            if(data.status){
                                this.$store.commit('snackBar',{show:true,message:"密碼變更成功！請重新登入",icon:"success",color:""});
                                setTimeout(()=>{
                                    this.$router.push({path:'/login'});
                                },1500)
                            }else if(data.code==2){
                                this.$store.commit('snackBar',{show:true,message:"帳號輸入錯誤！",icon:"error",color:""});

                            }else{
                                this.$store.commit('snackBar',{show:true,message:"密碼變更失敗！",icon:"error",color:""});

                            }

                        })
                }else{
                        this.$store.dispatch("http/put",{
                            api:"users/password/reset",
                            json:{
                                old_password:this.old_password,
                                new_password:this.new_password,
                                confirm_password:this.confirm_password
                            }
                        
                        }).then((data)=>{
                            if(data.status){
                                this.$store.commit('snackBar',{show:true,message:"密碼變更成功！請重新登入",icon:"success",color:""});
                                setTimeout(()=>{
                                    this.$router.push({path:'/logout'});
                                },1500)
                            }else if(data.code==2){
                                this.$store.commit('snackBar',{show:true,message:"舊密碼輸入錯誤！",icon:"error",color:""});

                            }else{
                                this.$store.commit('snackBar',{show:true,message:"密碼變更失敗！",icon:"error",color:""});

                            }

                        })
                }
            }
       }
        
    }
}
</script>