<template>
  <v-app>
<v-main>
    <v-toolbar color="primary" class="white--text">
      <v-toolbar-title>GfK UCAPP帳戶驗證</v-toolbar-title>
    </v-toolbar>
    <v-container class="mt-4 text-center">
        <v-card flat>
        <v-card-text class="pt-2" v-if="status==0">
          <div class="font-weight-medium headline">驗證中請稍候...</div>
          <v-progress-circular
            indeterminate
            color="primary"
            class="mt-4"
          ></v-progress-circular>
        </v-card-text>
        <v-card-text class="pt-2" v-else-if="status==1">
            <div class="font-weight-medium headline">驗證成功！你可以開始使用。頁面即將跳轉...</div>
            <v-icon size="60" color="green">mdi-check-circle-outline</v-icon>
        </v-card-text>
        <v-card-text class="pt-2" v-else-if="status==2">
            <div class="font-weight-medium headline">驗證失敗！請重新發送驗證信。頁面即將跳轉...</div>
              <v-icon size="60" color="red">mdi-alert-circle-outline</v-icon>
        </v-card-text>
      </v-card>
    </v-container>
   </v-main>
</v-app>
</template>
<script type="text/javascript"> 
export default {

  data: () => ({
    name:"",
    status:0,
  }),
  mounted(){
      if(this.$route.query.token){
          this.verifyToken();
      }else{
        alert("沒有存取權限，頁面即將跳轉")
        setTimeout(()=>{
            this.$router.push({path:'/login'});
        },2000)
      }
  },
    methods: {
        verifyToken(){
            this.$store.dispatch("http/put",{
                    api:"users/verify",
                    json:{
                        token:this.$route.query.token,
                    }
                    
                }).then((data)=>{
                    if(data.status){
                        this.status=1
                        setTimeout(()=>{
                            this.$router.push({path:'/'});
                        },2000)

                    }else{
                        this.status=2
                        setTimeout(()=>{
                            this.$router.push({path:'/login'});
                        },2000)
                    }
                })
        },
    } 
 }
</script>