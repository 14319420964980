<template>
  <v-app>
<v-main>
    <v-toolbar color="primary" class="white--text">
      <v-toolbar-title>GfK UCAPP 需要帳戶驗證</v-toolbar-title>
    </v-toolbar>

    <v-container class="mt-4">
        你的帳號尚未通過Email驗證，請重新發送驗證信，並前往GfK的電子郵件信箱完成驗證。
        <v-form ref="form" lazy-validation v-model="valid">
                <v-text-field
                    v-model="account"
                    :rules="$store.getters['validation/inputRules'](['required'])"
                    label="帳號"
                ></v-text-field>
                <v-row>
                    <v-col>
                        <p class="red--text" v-html="errMsg"></p>
                        <v-btn block color="primary" width="150"
                            @click="sendVerifyEmail"
                        >送出驗證信</v-btn>
                        <v-btn block text @click="$router.push({path:'/logout'});">這不是你的帳號嗎？ <span class="secondary--text">點此登出</span></v-btn>

                    </v-col>
                </v-row>

            </v-form>
    </v-container>
   </v-main>
</v-app>
</template>
<script type="text/javascript"> 
export default {

  data: () => ({
    name:"",
    errMsg:'',
    valid:false,
    account:'',
  }),
  mounted(){
  },
    methods: {
        sendVerifyEmail(){
            this.errMsg = ""
            if (this.$refs.form.validate()) {

                this.$store.commit('loadingBox',{show:true});
                this.$store.dispatch("http/post",{
                    api:"users/email/verify",
                    json:{
                        account:this.account,
                    }
                    
                }).then((data)=>{
                    if(data.status){
                        setTimeout(()=>{
                            this.$store.commit('loadingBox',{show:false});
                                this.$store.commit('dialogBox',{dialog:true,option:{
                                    btnConfirmText:'我知道了',
                                    message:'驗證信已經發送至GfK信箱，請立即收信並驗證你的帳號！',
                                    btnCancelShow:false,
                                    icon:'success',
                                    btnConfirmEvent:()=>{
                                    }
                                }});
                        },2500);
                    }else{
                        this.errMsg="伺服器發生錯誤請稍後再試";
                    }
                    
                })
            }
        },
    } 
 }
</script>