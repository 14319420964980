<template>
<v-app> 
    <v-main>
        <v-toolbar dense  prominent
            src="https://picsum.photos/1920/1080?random"
        >  
            <v-btn fab small color="white" 
                class="mt-3 mr-4"
                @click="$router.go(-1)"
            >
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <template v-slot:img="{ props }">
                <v-img 
                v-bind="props"
                gradient="to top right, rgba(53 140 219 / 34%), rgba(190 255 79 / 17%)"
                ></v-img>
            </template>
            <v-toolbar-title class="white--text"><h3>帳戶</h3><small>{{$store.state.user.user_name}}</small></v-toolbar-title>
            <v-spacer></v-spacer>
            <p class="mt-5 mr-3 white--text"
            ><strong>{{$store.state.version}}</strong></p>
            <v-btn  outlined color="white" 
                class="mt-3"
                @click="$router.push({path:'/logout'})"
            >登出</v-btn>
        </v-toolbar> 
        
        <v-sheet class="mt-6">
            <v-container class="text-center">
                <v-row >
                    <v-col cols=6 sm=4 
                        v-for="(item,idx) in menu"
                        :key="'menu-'+idx"  
                    >
                        <div v-if="checkRolePermission(item.included_roles)">
                            <v-card dark :color="item.color"
                                class="d-flex align-center"
                                height="150" min-width="120"
                                @click="$router.push({path:item.path})"
                            >
                                <v-container>  
                                    <v-badge color="" overlap :content="item.badge"><v-icon large class="mb-2">{{item.icon}}</v-icon></v-badge> 
                                    <h2>{{item.text}}</h2> 
                                </v-container>
                            </v-card>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
    </v-main>
</v-app>
</template>
<script type="text/javascript">

export default {
    components:{ },

  mounted(){
   },
  data: () => ({
     menu:[
         {  
             path:'/password/reset',
             color:"primary",
             text:"修改密碼",
             badge:"",
             icon:"mdi-shield-edit",
             included_roles:[]
         },
          {  
             path:'/draft',
             color:"info",
             text:"我的草稿",
             badge:"",
             icon:"mdi-file-document",
             included_roles:[]
         },
         {  
             path:'/reports',
             color:"secondary",
             text:"報表下載",
             badge:"",
             icon:"mdi-file-chart",
             included_roles:[1,2]
         },
          {  
             path:'/manage/list',
             color:"accent",
             text:"選項管理",
             badge:"",
             icon:"mdi-format-list-bulleted-type",
             included_roles:[1,2]
         },
          {  
             path:'/imports',
             color:"third",
             text:"批量管理",
             badge:"",
             icon:"mdi-database-search",
             included_roles:[1,2]
         },
          {  
             path:'/manage/admin',
             color:"grey",
             text:"權限管理",
             badge:"",
             icon:"mdi-account-lock",
             included_roles:[1,2]
         }
     ]
  }),
  computed:{
      total_draft(){
          return this.$store.getters['draft/count']()+this.$store.getters['draft_mallshop/count']()
      }
  },

  methods: {
     
    checkRolePermission (included_roles) {
        if(included_roles.length==0){
            return true
        }
        if(included_roles.includes(this.$store.state.user.user_role)){
            return true
        }
        return false
    },
     
  }
}
</script>